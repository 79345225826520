import dayjs from 'dayjs';
import { isNil, isString } from 'lodash-es';

export const timestampStringToDayjs = (
	timeSearchParams: string | number | null | undefined,
) => {
	return !isNil(timeSearchParams) && timeSearchParams !== 'undefined'
		? isString(timeSearchParams)
			? dayjs(parseInt(timeSearchParams, 10))
			: dayjs(timeSearchParams)
		: dayjs();
};
